import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import Layout from "../../components/layout.js"
import { InPageNav } from "../../components/navbar.js"
import { Section } from "../../components/sections/sections.js"
import SimpleTabs from "../../components/molecules/tabs.js"
// import SelectInput_v1 from "../../components/elements/selectInput.js"
import {
  SectionTitle,
  SectionDescription,
} from "../../components/typography.js"

import Sophia from "../../images/sophia.png"

const ImgWrap = styled.img`
  width: 20%;
  border-radius: 50%;
  margin-right: 10%;
`
const Flex = styled.div`
  display: flex;
  flex-flow: row-reverse nowrap;
  align-items: center;
  justify-conten: center;
  width: 100%;
`
const Span = styled.span`
  color: ${({ color }) => (color ? color : "#f50057")};
`
const Content = styled(SectionDescription)`
  font-size: 1.5em;
  letter-spacing: -0.04em;
  font-weight: 400;
  line-height: 1.6;
  width: 60%;
  padding: 1em;
`
const InstructorSophia = () => {
  const [currTab, setTab] = useState(0)
  const RedirectToPage = dest => {
    navigate(dest)
  }
  return (
    <Layout notification={true}>
      <InPageNav
        pageTitle="Our Team"
        navItems={[
          { link: "/instructors", label: "Overview" },
          { link: "/instructors/humayun_ahmed", label: "Humayun A." },
          { link: "/instructors/sophia_glisch", label: "Sophia G." },
          { link: "/instructors/sohaib_ahmed", label: "Sohaib A." },
        ]}
        clickCallback={RedirectToPage}
      />
      <Section>
        <SectionTitle>Sophia Glisch</SectionTitle>

        <Flex>
          <ImgWrap src={Sophia} alt="Sohaib Ahmed's profile picture" />
          <SimpleTabs
            tabs={[
              { label: "Who is he?" },
              { label: "What does he teach?" },
              { label: "His achievements" },
              { label: "His Testimonials" },
            ]}
            handleTabs={data => {
              setTab(data)
            }}
          />
        </Flex>
        {currTab === 0 ? (
          <>
            <Content>
              Sohaib Ahmed is our <Span>Chief Operating Officer</Span>. He has
              strong interests in:
              <br />
              <br />
              {
                <ol style={{ margin: "0", paddingBottom: "0" }}>
                  <li>Health sciences</li>
                  <li>Information technology</li>
                  <li>Social good</li>
                  <li>Education amelioration</li>
                  <li>
                    And, just generally, in any projects that focus on elegant,
                    socially impactful solutions
                  </li>
                </ol>
              }
            </Content>
            <Content>
              He has spent 5+ years engaging with
              <Span> hospital and clinical settings </Span>
              and basic <Span>science research</Span>. At the same time, he
              pursued undergraduate training in <Span>philosophy</Span> and
              <Span> bioethics</Span>. Finally, prior to joining 99point9, he
              was <Span>Chief Operating Officer of MD Home Call</Span>, a
              start-up geared at delivering health care to patients in need of
              house calls. Now, at 99point9, he runs the entirety of Operations,
              and works with the Co-Executive Directors to define vision,
              curricula, student acquisition and instructor recruitment
              strategies, logistics, and promotions. He also frequently speaks
              at student-centered events, sharing the story of how he went from
              premed to C-level executive at MDHC shortly after his
              undergraduate studies.
            </Content>
            <Content>
              During our courses, Sohaib breaks from his executive duties, and
              focuses heavily on engaging our students while Sophia and Humayun
              teach, supporting 99point9ers as they interact with our intense
              curriculum. Seek him out, and he'll gladly converse with you about
              your career plan, help you define your study strategies for the
              MCAT and the LSAT, introduce you to business fundamentals, and
              ideate solutions to your academic difficulties
            </Content>
          </>
        ) : currTab === 1 ? (
          <></>
        ) : currTab === 2 ? (
          <></>
        ) : (
          <></>
        )}
      </Section>
    </Layout>
  )
}

export default InstructorSophia
