import React, { useState } from "react"
import styled, { keyframes } from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid rgb(0, 0, 0, 0.12);
`
const TabWrap = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  border-bottom: ${props =>
    props.textColor === "white" ? "1px solid rgb(255,255,255,0.12)" : "none"};
`

export const Tab = styled.button`
  position: relative;
  top: 1px;
  appearance: none;
  margin: 16px 3px 0px;
  overflow-wrap: break-word;
  font-weight: 500;
  color: ${props => (!props.active ? props.textColor : "#F50057")};
  text-transform: none;
  display: inline-block;
  position: relative;
  padding: 0px 0.75rem;
  background-color: transparent;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 0px;
  transition: all 1s cubic-bezier(0.645; 0.045; 0.355; 1) 0.5s;
  line-height: 44px;
  height: 48px;
  font-size: 1em;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  border: none;
  border-bottom: ${props =>
    props.active && !props.progress
      ? "2px solid #F50057"
      : "2px solid transparent"};
  width: auto;
  outline: none;
`

const SimpleTabs = ({ tabs, handleTabs, textColor }) => {
  const [active, setActive] = useState(0)
  const handleClick = index => {
    setActive(index)
    handleTabs(index)
  }
  return (
    <Wrapper>
      <>
        <TabWrap textColor={textColor || "black"}>
          {tabs && tabs.length > 0
            ? tabs.map((tab, index) => (
                <Tab
                  active={index === active ? true : false}
                  key={JSON.stringify(tabs) + index}
                  onClick={() => {
                    handleClick(index)
                  }}
                  textColor={textColor || "black"}
                >
                  {tab.label}
                </Tab>
              ))
            : null}
        </TabWrap>
      </>
    </Wrapper>
  )
}

export default SimpleTabs
